* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 75%,
    rgba(3, 92, 110, 1) 100%
  );
}
